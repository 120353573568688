import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import Modal from "react-responsive-modal";

import Layout from "../components/Layout";
import SectionLayout from "../components/SectionLayout";
import SEO from "../components/seo";

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

class Index extends React.Component {
     state = {
        open: [false, false, false]
      };
    
      onOpenModal = id => {
        const { open } = this.state;
        open[id] = !open[id];
        this.setState({ open });
      };
    
      onCloseModal = id => {
        const { open } = this.state;
        open[id] = !open[id];
        this.setState({ open });
      };
  render() {
    {this.onOpenModal.bind(null, this.props.number)
      const { open } = this.state;
      const { data } = this.props;
      const siteTitle = data.site.siteMetadata.title;
  
      const modalStyles = {
        modal: {
          padding: "4em"
        }
      };
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, "career"]}
        />

<Modal
      center
      open={open[0]}
      onClose={this.onOpenModal.bind(null, 0)}
      styles={modalStyles}
    >
         <StyledH1>Front-end Developer</StyledH1>
      <p style={{ marginBottom: "1em" }}>
        We seek a passionate Javascript developer intern to enhance our
        startup.
      </p>
      {/* <p
        style={{
          marginBottom: "2em",
          backgroundColor: "rgba(32, 222, 32, 0.475)"
        }}
      >
        The internship starts after the Exam Period, 1st of July, and
        finishes the 31st of September.
      </p> */}
      <h2>Prerequisites</h2>
      <p>
        <ul>
          <li>
            <span>
              Basic knowledge of Data Analysis/Data Structures concepts
            </span>
          </li>
          <li>
            <span> Javascript</span>
          </li>
          <li>
            <span> node.js</span>
          </li>
          <li>
            <span> HTML and CSS</span>
          </li>
        </ul>
      </p>
      <h2>Good to have</h2>
      <p>
        <ul>
          <li>
            <span> ReactJS, Redux(or MobX) basics</span>
          </li>
        </ul>
      </p>
      <a
        className="btn btn--sm btn--primary type--uppercase"
        href="mailto:info@loceye.io?subject=Frontend Developer Internship | Loceye"
        style={{ marginTop: "2em" }}
      >
        <span className="btn__text"> Send My CV </span>
      </a>
    </Modal>
    <Modal
      center
      open={open[1]}
      onClose={this.onOpenModal.bind(null, 1)}
      styles={modalStyles}
    >
      <StyledH1>Quality & Assurance</StyledH1>
      <p style={{ marginBottom: "1em" }}>
        Looking for a passionate Intern, to join our team and improve
        her/his skills. You do not need any advanced hard skills to join as
        an intern. We will train you for the posotion. Some tasks will
        include:
        <ul>
          <li>
            - Test our systems configuration and maintenance based on
            complex environments and cloud deployments
          </li>
          <li>- Automation testing operation</li>
          <li>- Products delivery activities support</li>
          <li>- Identification, documentation and tracking of bugs</li>
          <li>
            - Identification of product areas requiring quality improvement
          </li>
        </ul>
      </p>
      {/* <p
        style={{
          marginBottom: "2em",
          backgroundColor: "rgba(32, 222, 32, 0.475)"
        }}
      >
        The internship starts after the Exam Period, 1st of July, and
        finishes the 31st of September.
      </p> */}
      {/* <h2>Prerequisites</h2>
      <p>
        <ul>
          <li>
            <span>Less than 10 courses to graduate</span>
          </li>
          <li>
            <span>
              Basic knowledge of Data Analysis/Data Structures concepts
            </span>
          </li>
          <li>
            <span>Currently enrolled in the last year of the school</span>
          </li>
          <li>
            <span>
              Knowledge of any of the following programming languages:
              Python, C++, C
            </span>
          </li>
        </ul>
      </p> */}
      {/* <h2>Good to have</h2>
      <p>
        <ul>
          <li>
            <span>
              Passion about building stuff and learning new technologies
            </span>
          </li>
          <li>
            <span>Scrapping Experience</span>
          </li>
          <li>
            <span>Image processing Experience</span>
          </li>
        </ul>
      </p> */}
      <a
        className="btn btn--sm btn--primary type--uppercase"
        href="mailto:info@loceye.io?subject=Quality Assurance Internship | Loceye"
        style={{ marginTop: "2em" }}
      >
        <span className="btn__text"> Send My CV </span>
      </a>
    </Modal>
        <SectionLayout
          description="We're looking for talented people who are passionate about innovation."
          title="Careers"
        >
          <div className="container">
            {/*end of row*/}
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="feature feature-1 boxed boxed--border">
                  <span className="label">Internship</span>
                  <h5>
                    Quality & Assurance
                    <br />
                    Intern
                  </h5>
                  <p></p>
                  <a href="#" onClick={this.onOpenModal.bind(null, 1)}>
                    View &amp; Apply
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="feature feature-1 boxed boxed--border">
                  <span className="label">Fulltime</span>
                  <h5>Front-end developer</h5>
                  <p>Javascript</p>
                  <a href="#" onClick={this.onOpenModal.bind(null, 0)}>
                    View &amp; Apply
                  </a>
                </div>
              </div>
            </div>
          </div> 
        </SectionLayout>
      </Layout>
    );
  }
}
}
export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
